import * as http from '../http'

export default {
  resourceList: params => {
    return http.post('/resource/menu/list', params)
  },
  all: params => {
    return http.post('/menu/tree', params)
  },
  add: params => {
    return http.post('/menu/add', params)
  },
  update: params => {
    return http.post('/menu/update', params)
  },
  remove: params => {
    return http.post('/menu/delete', params)
  },
  list: params => {
    return http.post('/resources/list', params)
  },
  getDetail: params => {
    return http.post('/menu/detail', params)
  }
}
