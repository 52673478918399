<template>
  <el-row class="warp">
    <el-col :span="24" class="warp-main" v-loading="loading" element-loading-text="拼命加载中">
      <!--工具条-->
      <el-form :inline="true">
        <el-form-item>
          <el-button type="primary" size="small" @click="showAddDialog">新增</el-button>
        </el-form-item>
      </el-form>
      <el-table :data="listData" ref="tableTree" :fit="true" @selection-change="handleSelectionChange">
        <el-table-column prop="id" label="id" type="selection"></el-table-column>
        <el-table-tree-column
          fixed
          file-icon="icon icon-file"
          folder-icon="icon icon-folder"
          prop="name"
          label="名称"
          child-key="children"
          parentKey="parentId"
          levelKey="level"
          :show-overflow-tooltip="true"
          :resizable="true"
          min-width="180px"
          :indent-size="20"
        >
          <template slot-scope="scope">
            <span class="iconfont" :class="scope.row.icon" style="margin-right: 4px" />
            <span>{{ scope.row.name }}</span>
          </template>
        </el-table-tree-column>
        <el-table-column prop="url" label="导航路径" :show-overflow-tooltip="true" />
        <el-table-column prop="key" label="关键字" :show-overflow-tooltip="true" />
        <el-table-column prop="level" label="菜单等级" />
        <el-table-column prop="sort" label="菜单排序" />
        <el-table-column prop="description" label="描述" :show-overflow-tooltip="true" />
        <el-table-column prop="icon" label="操作" width="140px">
          <template slot-scope="scope">
            <el-button-group>
              <el-button size="small" @click="showEditDialog(scope.row.id)">编辑</el-button>
              <el-button type="danger" size="small" @click="del(scope.row)">删除</el-button>
            </el-button-group>
          </template>
        </el-table-column>
      </el-table>
    </el-col>
    <!--新增界面-->
    <el-dialog v-if="addEditFormVisible" :title="addEditTitle" :visible.sync="addEditFormVisible" :close-on-click-modal="false" width="600px">
      <el-form :model="addEditForm" :rules="addEditFormRules" ref="addEditForm" size="small" label-width="80px">
        <el-row type="flex">
          <el-col :span="12" v-if="addEditType === 'add'">
            <el-form-item label="父节点" prop="parentName">
              <el-input v-model="addEditForm.parentName" disabled />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="节点等级" prop="level">
              <el-input v-model="addEditForm.level" disabled />
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="名称" prop="name">
          <el-input v-model="addEditForm.name" maxlength="50" show-word-limit placeholder="请输入名称" />
        </el-form-item>
        <el-form-item label="菜单标识" prop="code">
          <el-input v-model="addEditForm.code" maxlength="200" show-word-limit placeholder="请输入菜单标识" />
        </el-form-item>
        <el-form-item label="图标" prop="icon">
          <el-input v-model="addEditForm.icon" type="text" autosize v-if="addEditForm.resType == 2" />
          <el-select v-model="addEditForm.icon" filterable v-else>
            <el-option v-for="(item, index) in menuIcon" :key="index" :label="item.label" :value="item.value"><i class="iconfont" :class="item.label" />{{ item.label }} </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="排序" prop="sort">
          <el-input-number v-model="addEditForm.sort" :min="1" :max="200" />
        </el-form-item>
        <!-- <el-form-item label="平台" prop="platformType">
          <el-select v-model="addEditForm.platformType">
            <el-option v-for="(item, index) in platFormOptions" :key="index" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item label="类型" prop="type">
          <el-select v-if="addEditFormVisible" v-model="addEditForm.type" @change="resourceTypeChange">
            <el-option v-for="(item, index) in resourcesTypeOptions" :key="index" :label="item.label" :value="item.value"> {{ item.label }} </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="资源" prop="resIds">
          <el-select v-model.trim="addEditForm.resIds" filterable multiple style="width: 100%">
            <el-option v-for="item in resourceList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="描述" prop="description">
          <el-input v-model="addEditForm.description" type="textarea" maxlength="200" show-word-limit />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click.native="addEditFormVisible = false">取消</el-button>
        <el-button v-if="addEditType === 'add'" type="primary" @click.native="addSubmit">保存</el-button>
        <el-button v-else type="primary" @click.native="editSubmit">保存</el-button>
      </div>
    </el-dialog>
  </el-row>
</template>
<script>
import API from '@/api/system/api_resources'
import _ from 'lodash'
import { menuIcon, platFormOptions, resourcesTypeOptions } from '@/config/config.js'
const addEditForm = {
  parentId: 0,
  parentName: '根节点',
  level: 1,
  name: null,
  code: null,
  icon: null,
  sort: 1,
  type: 1,
  resIds: [],
  description: null
}
export default {
  data() {
    return {
      loading: false,
      listData: [],
      data_hash: {},
      menuIcon,
      platFormOptions,
      resourcesTypeOptions,
      addEditTitle: '',
      addEditType: 'add',
      addEditFormVisible: false,
      addEditForm: _.cloneDeep(addEditForm),
      addEditFormRules: {
        name: [{ required: true, message: '请输入名称', trigger: 'blur' }],
        code: [{ required: true, message: '请输入菜单标识', trigger: 'blur' }]
      },
      multipleSelection: [],
      resourceList: []
    }
  },
  mounted() {
    this.getAll()
    // this.getResourceList()
  },
  methods: {
    /**
     * 获取所有菜单
     */
    getAll() {
      API.all().then(result => {
        if (result && result.code === '000000') {
          this.listData = result.data.list
          this.put_hash_data(result)
        }
      })
    },
    /**
     * 将树的数组对象存放为HASH对象
     */
    put_hash_data(treeArr) {
      if (treeArr && treeArr.length > 0) {
        treeArr.forEach(item => {
          this.data_hash[item.id] = item
          let c = item.children
          if (c.length > 0) {
            this.put_hash_data(c)
          }
        })
      }
    },
    resourceTypeChange(val) {
      if (val === 2) {
        this.getResourceList()
        this.addEditForm.icon = null
      }
    },
    getResourceList(platformType) {
      console.log(platformType)
      let params = {
        platformType: platformType ? platformType : this.multipleSelection && this.multipleSelection.length > 0 ? this.multipleSelection[0].platformType : 3
      }
      API.resourceList(params).then(result => {
        if (result && result.code === '000000') {
          this.resourceList = result.data.list
        }
      })
    },
    /**
     * 选中行
     */
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    /**
     * 新增
     */
    showAddDialog() {
      this.addEditTitle = '新增'
      this.addEditType = 'add'
      this.addEditForm = _.cloneDeep(addEditForm)
      if (this.$refs.addEditForm !== undefined) {
        this.$refs.addEditForm.resetFields()
      }
      let selectLength = this.multipleSelection.length
      if (selectLength > 1) {
        this.$message.warning({ showClose: true, message: '只能选择一个父节点', duration: 2000 })
        return
      }
      if (selectLength === 0) {
        this.$confirm('确认添加根节点吗?', '提示', { type: 'warning' })
          .then(() => {
            this.addEditFormVisible = true
          })
          .catch(() => {})
      } else {
        let row = this.multipleSelection[0]
        this.addEditForm = {
          ...this.addEditForm,
          parentId: row.id,
          parentName: row.name,
          level: row.level + 1
        }
        if (this.addEditForm.level > 5) {
          this.$message.success({ showClose: true, message: '最多支持五级节点', duration: 2000 })
          return
        }
        this.addEditFormVisible = true
      }
    },
    /**
     * 新增提交
     */
    addSubmit() {
      this.$refs.addEditForm.validate(valid => {
        if (valid) {
          let params = Object.assign({}, this.addEditForm)
          delete params.parentName
          API.add(params).then(result => {
            if (result && result.code === '000000') {
              this.$message.success({ showClose: true, message: result.msg || '新增成功', duration: 2000 })
              this.addEditFormVisible = false
              this.getAll()
            } else {
              this.$message.error({ showClose: true, message: result.msg || '新增失败', duration: 2000 })
            }
          })
        }
      })
    },
    /**
     * 获取菜单详情
     */
    async getDetail(id) {
      let params = { id }
      let { code, data } = await API.getDetail(params)
      if (code === '000000') {
        return data
      }
    },
    /**
     * 显示编辑界面
     */
    async showEditDialog(id) {
      this.addEditTitle = '编辑'
      this.addEditType = 'edit'
      let res = await this.getDetail(id)
      if (res.resources && res.resources.length > 0) {
        res.resIds = res.resources.map(e => e.id)
      }
      this.addEditForm = _.cloneDeep(res)
      this.getResourceList(this.addEditForm.platformType)
      this.addEditFormVisible = true
    },
    /**
     * 编辑提交
     */
    editSubmit() {
      this.$refs.addEditForm.validate(valid => {
        if (valid) {
          this.loading = true
          let params = Object.assign({}, this.addEditForm)
          delete params.parentName
          API.update(params).then(result => {
            this.loading = false
            if (result && result.code === '000000') {
              this.$message.success({ showClose: true, message: result.msg || '修改成功', duration: 2000 })
              this.addEditFormVisible = false
              this.getAll()
            } else {
              this.$message.success({ showClose: true, message: result.msg || '修改失败', duration: 2000 })
            }
          })
        }
      })
    },
    /**
     * 删除
     */
    del(row) {
      if (row.children.length > 0) {
        this.$message.error({ showClose: true, message: '有子节点不能被删除', duration: 2000 })
        return
      }
      this.$confirm('确认删除该记录吗?', '提示', { type: 'warning' }).then(() => {
        this.loading = true
        API.remove({ id: row.id }).then(
          result => {
            this.loading = false
            if (result && result.code === '000000') {
              this.$message.success({ showClose: true, message: result.msg || '删除成功', duration: 2000 })
              this.getAll()
            } else {
              this.$message.error({ showClose: true, message: result.msg || '删除失败', duration: 2000 })
            }
          },
          result => {
            this.loading = false
            this.$message.error({ showClose: true, message: result.msg, duration: 2000 })
          }
        )
      })
    }
  }
}
</script>
